#gradient{
    background-image: url('../../Assets/gradient.png');
    background-position: -100px 0px  ;
    background-size: cover;
    z-index: 2;
}

#auth-page{
    display: flex;
}

.auth-right-container{
    width: 75vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    background-color: #fffdfa;
    position: relative;
  
}

#auth-left-container{
    width: 25vw;
    background-color: #f1f1f1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.wrapper {
    position:relative;
  }
  
.auth-input-icon {
    height: 25px;
    position: absolute;
    box-sizing:border-box;
    top:50%;
    right:15px;
    transform: translateY(-50%);
}

.profile-choice{
    width: 694px;
    height: 114px;
    border: 0.6px solid #d4d4d4;
    border-radius: 8px;
    padding: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem ;
    margin-bottom: 2rem;
}

.profile-choice-active{
    width: 694px;
    height: 114px;
    border: 0.6px solid #272838;
    border-radius: 8px;
    padding: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem ;
    margin-bottom: 2rem;
    background-color: #fdfdfd;
}

.profile-choice:hover, .profile-choice-active:hover{
    cursor: pointer;
}

.profile-choice-square{
    background-color: #d9d9d9;
    border-radius: 4px;
    height: 80px;
    width: 80px;
    flex-shrink: 0;
    margin-right: 17px;
}

.check-circle{
    height: 32px;
    width: 32px;
    border-radius: 16px;
    background-color: #ebebeb;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#sign-up-steps{
    border-radius: 24px;
    min-height: 415px;
    width: 413;
    background-color: white;
    padding: 1rem;
}

.sign-up-step{
    min-height: 68px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin: 10px 0;
}