#tabs{
    display: flex;
    flex-direction: row;
    margin: 10px 0 0 10px;
    width: 100vw;
    height: 70px;
}

.profile-tab{
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.tab:hover{
    cursor: pointer;
}

.profile-tab-active{
    border-top: 1px solid grey;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}