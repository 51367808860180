#success{
    display: flex;
    justify-content: center;
    padding: 2rem;
    height: 100vh;
    width: 100vw;
    background-image: url('../../Assets/landing-desktop.jpg');
    overflow: scroll;
    position: relative;

}

#success-info{
    display: flex;
    align-items: center;
    flex-direction: column;
    color: white;
    z-index: 999;
    font-size: 1.2rem;
    width: 700px;
    max-width: 100%;
    padding-bottom: 2rem ;
}

#success-info > * {
    margin: 1rem 0;
}

#page-overlay{
    position: fixed;
    background-color: rgba(39, 40, 56, 0.9);
    height: 100%;
    width: 100%;
  
  }