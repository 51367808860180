*{
  box-sizing: border-box;
}

 #login{
   position: absolute;
   top: 30px;
   right: 30px;
   z-index: 999;
 }

#p1 { 
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  left:0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url('../../Assets/landing-desktop.jpg');
  background-position: left;
  background-size: cover;
  overflow: scroll;

}

#page-overlay{
  position: absolute;
  background-color: rgba(39, 40, 56, 0.9);
  height: 100vh;
  width: 100vw;

}

#p1 > * {
  z-index: 999;
}

.sign-up-input{
  height: 50px;
  width: 350px;
  border-radius: 25px;
  border: unset;
  padding-left: 10px;
  font-size: 16px;
}

#landing-logo{
  width: 300px;
}

.subscribe-btn{
  background-color: #EB9486;
  border: unset;
  color: white;
  height: 30px;
  width: 100px;
  border-radius: 15px;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

#landing-form{
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 90vw;
  align-items: center;
  color: white;

}

#landing-form select, #landing-form input {
  height: 40px;
  width: 100%;
  margin: 10px 0;
  border-radius: 5px;
  border: unset;
  background-color: white;
  padding-left: 10px;
  font-size: 16px;
}

#landing-form button {
  background-color: #EB9486;
  color: white;
  height: 40px;
  width: 150px;
  max-width: 90vw;
  margin: 10px;
  border-radius: 5px;
  border: unset;
}

#comp-btn{
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  #p1 {
    background-image: url('../../Assets/landing-mobile.jpg');
  }
}