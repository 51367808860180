#msg-list-container{
    width: 350px;
    height: 100vh;
    background-color: #EB9486;
    padding: 1.5rem;
}

#msg-list{
    height: 80%;
    width: 100%;
    background-color: white;
    border-radius: 25px;
    margin: 2rem 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;    

}

#msg-search{
    width: 100%;
    height: 50px;
    border-radius: 25px;
    border: unset;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;    
    padding-left: 10px;

}