.verify-account{
    display: flex;
    width: 400px;
    max-width: 90vw;
    padding: 1rem;
    border: 2px solid crimson;
    border-radius: 10px;
    margin: 1rem;
    background-color: rgb(255, 230, 230);

}

.id-pending{
    display: flex;
    width: 400px;
    max-width: 90vw;
    padding: 1rem;
    border: 2px solid orange;
    border-radius: 10px;
    margin: 1rem;
    background-color: rgb(252, 230, 188);

}