.add-container{
    width: 300px;
    max-width: 95vw;
    height: 250px;
    border: 1px dashed grey;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    transition: 0.5s;
}

.add-container:hover{
    border: 1px dashed #dfdfdf;
    cursor:pointer

}

.portfolio-input{
    margin: 10px 0;
    padding: 10px;
    width: 350px;
    max-width: 80vw;
    border: 1px solid grey;
    border-radius: 10px;
    font-size: 16px;
}

.close-btn{
    margin: 10px 0;
    width: 100px;
    border: unset;
    border-radius: 5px;
    padding: 5px;
}

.portfolio-item{
    width: 250px;
    max-width: 95vw;
    height: 250px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin: 1rem;
    position: relative;
}

.purple-gradient:hover{
    cursor: pointer;
    background-color: rgba(39, 40, 56, 0.5);
}

.purple-gradient{
    background: linear-gradient(135deg, #EB9486 20%, #272838);
    position: absolute;
    top: 0;
    left: 0;
    height: 250px;
    width: 250px;
    max-width: 95vw;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem;
    transition: 0.5s ;
}