#business-nav {
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: #fff;
    width: 16rem;
    padding: 3rem 1.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: width 0.5s ease-in-out;
    z-index: 999;
    border-right: 1px solid #f1f1f1;
  }
#business-nav::before {
    content: "";
    position: absolute;
    width: 2rem;
    height: 100vh;
    top: 0;
    left: 100%;
}

#nav-welcome{
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    justify-content: center;
}

#nav-welcome > p{
    font-size: 0.8rem;
}

#business-avatar{
    height: 50px;
    width: 50px;
    border-radius: 15px;
    background-color: #dfdfdf;
    box-shadow: rgba(0, 0, 0, 0.65) 0px 5px 15px;
    background-size: cover;
    background-position: center;
}

#business-avatar:hover{
    cursor: pointer;
}

.business-link-txt{
    font-size: 16px;
    font-family: 'Satoshi', sans-serif;
    color: #211F1F;
}

.business-link-txt-active{
    font-size: 16px;
    font-family: 'Satoshi', sans-serif;
    color: #EB9486;
}

#business-logout-btn{
    border: unset;
    background: unset;
}

.business-nav-item{
    display: flex;
    align-items: center;
    padding: 12px;
    margin: 10px 0;
    border-radius: 15px;
    transition: 0.5s;
}

.business-nav-item:hover{
    background-color: #f1f1f1;
    border-radius: 15px;
    transition: 0.5s;

}

.business-nav-item-active{
    display: flex;
    align-items: center;
    padding: 12px;
    margin: 10px 0;
    background-color: #272838;
    border-radius: 15px;
    transition: 0.5s;
}

.business-outlet{
    width: calc(100vw - 16rem);
    height: 100vh;
    position: absolute;
    left: 16rem;
    top: 0;
    background-color: #fffdfa;
    overflow: scroll;

}

.nav-icon{
    height: 30px;
    width: 30px;
    margin-right: 15px;
    opacity: 1;
}
.icon-filter{
    filter:  invert(100%) sepia(89%) saturate(408%) hue-rotate(309deg) brightness(98%) contrast(87%);
}

.list-overview-img{
    height: 60px;
    width: 60px;
    border: unset;
    border-radius: 8px;
    background-color: unset;
    object-fit: cover;
    object-position: center;

}

.applicant-card{
    border-radius: 8px;
    border: 2px solid #f1f1f1;
    padding: 2rem;
    width: 30%;
    overflow: hidden;
    height: 20vw;
    margin: 5px
}