.job-table{
    width: 100%;
}

.job-table tr:nth-child(odd) {
    background-color: #FAFBFB;
}

.job-table th, .job-table td{
    text-align: left;
    padding: 15px;
    color: #667085;
}

.more-info-cell:hover{
    cursor: pointer;
}

.job-description{
    background-color: #f5f5f5;
    width: 100%;
    padding: 1rem;
    position: relative;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

}

.job-textarea{
    background-color: unset;
    border: unset;
    margin: 10px 0;
    line-height: 30px;
}

.job-chip{
    background-color: #E0DADA;
    height: 30px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    margin: 10px 10px 10px 0;
}

.apply-input{
    width: 100%;
    height: 52px ;
    padding: 0 10px;
    border: 0.4px solid #d4d4d7;
    border-radius: 8px;
    font-size: 16px;
    margin: 10px 0;
}