#course-overview{
    width: 600px;
    min-height: 400px;
    max-width: 100vw;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 2rem auto;
}

#course-overview > *{
    margin: 1rem 0;
}

#start-btn, #back-btn{
    height: 40px;
    width: 100px;
    margin: 10px;
    border: unset;
    border-radius: 3px;

}

#start-btn{
    background-color: green;
    color: white;
}