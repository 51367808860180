.nav-logo{
 width: 60%;
 margin: 1rem auto;
 /*filter: brightness(0) invert(1);*/
}

.nav-link{
  color: white;
  font-size: 18px;
}



.nav-item{
  color: white;
  display: flex;
  align-items: center;
  width: 50%;
  margin: 2rem 0;
}

.nav-item p{
  margin-left: 10px;
}

#freelance-vatar{
    height: 60px;
    width: 60px;
    border-radius: 30px;
    background-color: rgb(187, 187, 187);
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 999;
}

#avatar:hover{
    cursor: pointer;
}

.bx{
  font-size: 24px;
}



#logout-btn{
    background-color: #EB9486;
    color: white;
    border: unset;
    height: 40px;
    border-radius: 20px;
    padding: 0 1rem;
    margin: 0 1rem ;
}

.freelance-nav {
    position: sticky;
    top: 0;
    left: 0;
    height: 100vh;
    /*background-color: #EB9486;*/
    /*background-color: #cc6c5c;*/
    background-color: #272838;
    width: 16rem;
    padding: 1.8rem 0.85rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: width 0.5s ease-in-out;
    z-index: 999;
  }
  nav::before {
    content: "";
    position: absolute;
    width: 2rem;
    height: 100vh;
    top: 0;
    left: 100%;
  }
  main {
    flex: 1;
    padding: 2rem;
    color: #1f2027;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
    position: relative;
    overflow: scroll;
    min-height: 100%;
    width: 100%;
    
  }
 
  .sidebar-top {
    position: relative;
    display: flex;
    align-items: center;
  }
  .sidebar-top .logo {
    width: 2.1rem;
    margin: 0 0.8rem;
  }
  .sidebar-top h3 {
    padding-left: 0.5rem;
    font-weight: 600;
    font-size: 1.15rem;
  }
  .shrink-btn {
    position: absolute;
    top: 50%;
    height: 27px;
    width: 27px ;
    padding: 0 0.3rem;
    background-color: #EB9486;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0 3px 10px -3px rgba(70, 46, 118, 0.3);
    right: -2.65rem;
    transform: translateY(-50%) translateX(-8px);
    opacity: 0.5;
    pointer-events: none;
    transition: 0.3s;
    color: white;
    z-index: 999;
  }
  .shrink-btn i {
    line-height: 27px;
    transition: 0.3s;
  }
  .shrink-btn:hover {
    background-color: #272838;
  }
  nav:hover .shrink-btn,
  .shrink-btn.hovered {
    transform: translateY(-50%) translateX(0px);
    opacity: 1;
    pointer-events: all;
  }

  /* When the menu shrinks */
  .hide {
    transition: opacity 0.3s 0.2s;
  }
  body.shrink nav {
    width: 5.4rem;
  }
  body.shrink .hide {
    opacity: 0;
    pointer-events: none;
    transition-delay: 0s;
  }
  body.shrink .shrink-btn i {
    transform: rotate(-180deg);
  }
 

  #divider{
    width: 100%;
    border-bottom: 1px solid grey;
    margin: 1rem 0;
  }
