#contest-content{
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100%;
    padding: 2rem;
}

.faq{
    width: 100%;
    background-color: white;
    padding: 1rem;
    min-height: 64px;
    height: auto;
    border-radius: 4px;
    margin: 1rem 0;
    border: 1px solid #f1f1f1;
    transition: 1s;

}
.faq:hover{
    cursor: pointer;
}

#contest-description{
    background-color: white;
    flex: 1;
    max-width: 550px;
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    position: relative;
    padding: 15px;

}

.faq-content{
    background-color: #fff;
    margin-top: 20px;

}

#create-contest{
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px);
    background-color: rgba(149, 157, 165, 0.5);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

#create-contest-container{
    position: absolute;
    right: 2rem;
    top: 2rem;
    height: 92vh;
    min-height: 600px;
    width: 612px;
    max-width: 90vw;
    background-color: #fefcfc;
    border-radius: 22px;
    padding-top: 75px;
}

#close-contest-btn{
    height: 42px;
    width: 42px;
    border-radius: 24px;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 999;
    border: 2px solid #d4d4d4;
    background-color: unset;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.create-contest-header{
    width: 100%;
    padding: 20px 50px;
    border-top: 2px solid #f1f1f1;
    border-bottom: 2px solid #f1f1f1;

}

.new-contest-container{
    background-color: #fff;
    width: 90%;
    margin: 1rem auto;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    padding: 1rem;
    overflow: scroll;
    height: 75%;
}

.contest-input{
    width: 100%;
    height: 52px ;
    padding: 0 10px;
    border: 0.4px solid #d4d4d7;
    border-radius: 8px;
    font-size: 16px;
}
.contest-textarea{
    width: 100%;
    height: 180px ;
    padding: 10px;
    border: 0.4px solid #d4d4d7;
    border-radius: 8px;
    font-size: 16px;
    resize: none;
}

#contest-next-btn{
    height: 42px;
    width: 42px;
    border-radius: 21px;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;    
    border: unset;
    background-color: #EB9486;
    display: flex;
    justify-content: center;
    align-items: center;

}
#contest-back-btn{
    height: 42px;
    width: 42px;
    border-radius: 21px;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;    
    border: unset;
    background-color: #EB9486;
    display: flex;
    justify-content: center;
    align-items: center;
}
#submit-contest-btn{
    position: absolute;
    color: white;
    margin: 0;
    bottom: 1rem;
    right: 1rem;
    z-index: 999;
    font-size: 16px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 10px;    

}