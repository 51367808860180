#competition{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    position: relative;
    left:0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url('../../Assets/landing-desktop.jpg');
    background-position: left;
    background-size: cover;
    overflow-y: scroll;
    padding: 2rem 0;
  
}

#page-overlay{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(39, 40, 56, 0.9);
    height: 100vh;
    width: 100vw;
    overflow: scroll;

  }

  #comp-form{
      z-index: 999;
      display: flex;
      flex-direction: column;
      width: 800px;
      max-width: 100vw;
      padding: 1rem;
  }

  #comp-form textarea {
      resize: none;
      height: 100px;
      padding: 10px;
      font-size: 16px;
      margin: 10px 0;
      border: unset;
      border-radius: 3px;
  }
  #comp-form input {
    resize: none;
    height: 40px;
    padding: 10px;
    font-size: 16px;
    margin: 10px 0;
    border: unset;
    border-radius: 3px;

}

.txt-limit{
    color: #dfdfdf;
    font-size: 0.8rem;
}

#comp-submit{
    margin: 10px auto;
    height: 40px;
    width: 100px;
    border: unset;
    border-radius: 3px;
    background-color: #EB9486;
    color: white;
    font-weight: bold;
}

#comp-info{
    z-index: 999;
    color: #fafafa;
    padding: 1rem;
    width: 900px;
    max-width: 100vw;
}

#comp-info p, #comp-info h1{
    margin: 15px 0;
    line-height: 25px;
    text-align: center;
}

li{
    list-style-type:decimal;
    line-height: 25px;
    margin: 15px 0;
    font-size: 18px;

}

ul{
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
}