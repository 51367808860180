#profile-left{
    width: 40%;
    height: calc(100vh - 100px);
    min-height: 500px;
    padding: 2rem;
}

#profile-right{
    width: 60%;
    height: calc(100vh - 100px);
    min-height: 500px;
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#primary-info{
    min-height: 500px;
    height: 80vh;
    width: 100%;
    min-width: 450px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    background-color: white;

}

#bio-container{
    width: 100%;
    min-width: 300px;
    height: 400px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 2rem;
    background-color: #f9e9de;


}

#skills-container, #language-container{
    border-radius: 20px;
    width: 47%;
    height: 300px;
    background-color: #f9e9de;


}

#skills-header, #language-header{
    border-bottom: 1px solid #dfdfdf;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
}

#profile-avatar{
    margin: 1rem auto;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    position: relative;
    background-size: cover;
    background-position: center;
}

.profile-input {
    border: unset;
    border-bottom: 1px solid #dfdfdf;
    padding: 10px;
    margin: 10px auto;
    width: 70%;

}

.profile-text-area{
    resize: none;
    border-radius: 20px;
    padding: 15px;
    border: unset;
    font-size: 16px;
    overflow: scroll;
}

.add-icon{
    width: 30px;
    height:30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EB9486;
    border-radius: 50%;
}
.add-icon:hover, .remove-cross:hover, #img-input:hover{
    cursor: pointer;
}

.profile-chip{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    height: 30px;
    border: unset;
    border-radius: 15px;
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #fff;

}

.item-input{
    border: unset;
    border-radius: 15px;
    height: 30px;
    padding-left: 10px;

}

.remove-cross{
    margin-left: 20px;
}

#img-input{
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;

}
