#jobs-dash-content{
    padding: 2rem;
}

#create-job-btn{
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: white;
}

#jobs-scroll-container{
    width: 100%;
    max-width: 1400px;
    height: 75vh;
    min-height: 700px;
    border: 1px solid #f1f1f1;
    border-radius: 8px ;
    overflow: scroll;
    padding: 1rem;
}

