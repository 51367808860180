.terms{
    width: 1000px;
    max-width: 95vw;
    margin: 2rem auto;
}

.term-header{
    font-weight: bold;
    margin: 2rem 0 1rem 0;
    font-size: 18px !important;
    font-family: "Satoshi" !important;
}

.terms p{
    font-size: 16px;
    font-family: 'Inter';
}