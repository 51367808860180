.course-container{
    width: 31%;
    height: auto;
    position: relative;
    margin: 1%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: linear-gradient(to right, #ffffff, #dfdfdf) ;
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    padding: 1rem;
}

.course-container:hover{
    cursor: pointer;
}

.course-img{
    object-fit: cover;
    object-position: center;
    height:80%;
    width: 100%;
    position: absolute;
}

#question-list{
    height: 100vh;
    width: 300px;
    display: flex;
    flex-direction: column;
}

.question-number-active{
    height: 80px;
    width: 300px;
    padding: 1rem;
    border-bottom: 1px solid #dfdfdf;
    font-weight: bold;
}

#question-container{
    background-color: #fafafa;
    height: 100vh;
    width: calc(100% - 300px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#question-container > *{
    margin: 1rem 0;
}

#submit-answer, #next-question{
    height: 40px;
    width: 100px;
    border: unset;
    margin: 10px;
    border-radius: 5px;
}

#submit-answer{
    background-color: green;
    color: white;
}

.question-checkbox{
    height: 25px;
    width: 25px;
    margin-right: 20px;
}

.answer-container{
    max-width: 90vw;
    width: 300px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.course-price{
    background-color: #EB9486;
    color: white;
    padding: 5px 10px;
    border-radius: 8px;
}

@media only screen and (max-width: 1675px) {
    .course-container{
        width: 48%;
    }
  }

  @media only screen and (max-width: 1200px) {
    .course-container{
        width: 100%;
    }
  }